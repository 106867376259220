<template>
  <div>
    <reverser-form/>
  </div>
</template>

<script>
import ReverserForm from './components/Main';

export default {
  components: {
    ReverserForm,
  }
}
</script>
